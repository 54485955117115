<template>
  <div>
    <v-sheet
      v-if="loading"
      class="d-flex align-center justify-center"
      style="height: 50vh"
    >
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-sheet>
    <div v-else>
      <v-sheet class="pa-5" elevation="1">
        <div
          class="header d-flex justify-space-between align-center mx-10 mt-10"
        >
          <h2>Trip ID: {{ order.customId }}</h2>
          <h2>
            Status: <span class="mr-3 primary--text">{{ order.tripStatus }}</span
            >
            <v-icon v-if="order.tripStatus == 'In Progress'" large color="secondary">mdi-progress-clock</v-icon>
            <v-icon v-else-if="order.tripStatus == 'Scheduled'" large color="primary">mdi-clock-outline</v-icon>
            <v-icon v-else color="success" large>mdi-check-all</v-icon>
          </h2>
          <div class="footer">
            <v-btn color="success" class="mr-5" @click="openPreTrip">Pre-Trip Report</v-btn>
            <v-btn color="primary" class="mr-5" @click="openPostTrip">Post-Trip Report</v-btn>
          </div>
        </div>
        <div class="content mt-10 mx-10">
          <v-row no-gutters>
            <!-- Order Info -->
            <v-col cols="4">
              <v-row no-gutters v-for="item in orderData" :key="item.title">
                <v-col cols="6">
                  <v-subheader class="secondary--text font-weight-bold">
                    {{ item.title }}
                  </v-subheader>
                </v-col>
                <v-col cols="6">
                  <v-subheader>
                  {{ item.value }}
                  </v-subheader>
                </v-col>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <!-- MAP -->
            <v-col cols="6" align-self="center">
              <div id="map" class="mt-5" style="height: 30em;"></div>
            </v-col>
          </v-row>
        </div>
      </v-sheet>
    </div>
    <v-dialog v-model="reportDialog" persistent scrollable>
      <trip-report :toggle="toggleTripReport" :reportType="reportType" :tripReport="tripReport" />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { Loader } from "@googlemaps/js-api-loader";
import TripReport from '../../components/order/TripReport.vue';

const MapsLoader = new Loader({
  apiKey: "AIzaSyCNFSebRQR4JK1qvH-yRGolz07ps57BAyA",
  version: "weekly",
  libraries: ["places"]
});
export default {
  components: { TripReport },
  data: () => ({
    loading: true,
    loader: MapsLoader,
    reportType: 'pre',
    tripReport: {},
    reportDialog: false,
    order: {},
    orderData: [],

  }),

  created() {
    setTimeout(async () => {
      this.order = await this.$store.dispatch('order/getOrderById', { id: this.$route.params.id })
      this.loading = false;

      console.log(this.order)

      this.orderData = [
        {
          title: "Van",
          value: this.order.vanCustomId,
        },
        {
          title: "Driver",
          value: `${this.order.driverFullName}`,
        },
        {
          title: "Trip",
          value: this.order.dropOffLocation.name,
        },
        {
          title: "Order hour",
          value: `${moment.unix(this.order.orderDateTime).format("L H:mm")}`,
        },
        {
          title: "Trip Date Time",
          value: `${moment.unix(this.order.pickUpDateTime).format('L H:mm')}`,
        },
        {
          title: "Client ID",
          value: this.order.customerCustomId,
        },
        {
          title: "Client Name",
          value: this.order.customerFullName,
        },
        {
          title: "Passenger(s)",
          value: this.order.passengers,
        },
        {
          title: "Luggage(s)",
          value: this.order.luggages,
        },
        {
          title: "Baby Seat",
          value: this.order.babySeats,
        },
        {
          title: "Payment Amount",
          value: '',
        },
        {
          title: "Payment Status",
          value: this.order.paymentStatus,
        },
      ];
      this.calculateFare()
      this.initMap();
    }, 2000);
  },

  methods: {

    toggleTripReport() {
      this.reportDialog = !this.reportDialog
    },

    openPreTrip() {
      this.reportType = 'pre'
      this.tripReport = this.order.preTripReport
      this.toggleTripReport()
    },

    openPostTrip() {
      this.reportType = 'post'
      this.tripReport = this.order.postTripReport
      this.toggleTripReport()
    },

    async calculateFare() {
      const parameters = await this.$store.dispatch('getParameters')
      let seat = `0.${parameters.seatPercent}`
      let luggage = `0.${parameters.luggagePercent}`
      if(Number(this.order.passengers) < 5) {
        seat = '0'
      }

      if(Number(this.order.luggages) < 7) {
        luggage = '0'
      }

      let calculateDistance = Number(this.order.estimatedDistance.split(' ')[0]) * Number(parameters.pricePerKm)
      let travelPrice = Number(parameters.basicFlatRate) + calculateDistance
      let totalPrice = travelPrice * (1 + Number(seat) + Number(luggage))

      this.orderData[11].value = Math.round((totalPrice + Number.EPSILON) * 100) / 100
    },

    initMap() {

      this.loader.load().then(() => {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();

        this.map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: -34.397, lng: 150.644 },
          zoom: 7,
          disableDefaultUI: true,
        });

        directionsService
          .route({
            origin: new google.maps.LatLng(`${this.order.pickUpLocation.lat}`, `${this.order.pickUpLocation.lng}`),
            destination: new google.maps.LatLng(`${this.order.dropOffLocation.lat}`, `${this.order.dropOffLocation.lng}`),
            travelMode: google.maps.TravelMode.DRIVING,
          })
          .then((response) => {
            directionsRenderer.setDirections(response);
          });

        directionsRenderer.setMap(this.map);
      });
    },
  },
};
</script>

<style></style>
