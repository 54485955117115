<template>
  <v-card color="" elevation="0" outlined class="pa-0">
    <div class="card-header">
      <v-card-title class="d-flex justify-space-between pa-5">
        <div class="white--text headline"><span v-if="reportType == 'pre'">Pre-trip</span> <span v-else>Post-trip</span> Report: {{ order.orderId }}</div>
        <v-icon color="white" class="d-none d-sm-flex" @click="toggle()"
          >mdi-close-thick</v-icon
        >
      </v-card-title>
    </div>
    <v-divider />
    <v-card-text style="height: auto">
      <v-row no-gutters class="mt-5">
        <!-- <v-col cols="6">
          <v-subheader class="secondary--text font-weight-bold">Observations</v-subheader>
          <p class="ml-5">{{ tripReport.observations }}</p>
          <v-subheader class="secondary--text font-weight-bold">Mileage</v-subheader>
          <p class="ml-5">{{ tripReport.mileage }}</p>
        </v-col> -->
        <v-col cols="12">
          <h3 class="secondary--text font-weight-bold text-center mt-4">Pictures</h3>
          <v-row class="mt-3" justify="center">
            <v-col cols="6">
              <p class="secondary--text font-weight-bold text-center">Front</p>
              <v-img v-if="!!tripReport.Front" max-height="250" max-width="450" lazy-src="https://picsum.photos/id/11/10/6" :src="tripReport.Front"></v-img>
              <v-skeleton-loader type="image" boilerplate v-else></v-skeleton-loader>
            </v-col>
            <v-col cols="6">
              <p class="secondary--text font-weight-bold text-center">Back</p>
              <v-img v-if="!!tripReport.Back" max-height="250" max-width="450" lazy-src="https://picsum.photos/id/11/10/6" :src="tripReport.Back"></v-img>
              <v-skeleton-loader type="image" boilerplate v-else></v-skeleton-loader>
            </v-col>
            <v-col cols="6">
              <p class="secondary--text font-weight-bold text-center">Left</p>
              <v-img v-if="!!tripReport.Left" max-height="250" max-width="450" lazy-src="https://picsum.photos/id/11/10/6" :src="tripReport.Left"></v-img>
              <v-skeleton-loader type="image" boilerplate v-else></v-skeleton-loader>
            </v-col>
            <v-col cols="6">
              <p class="secondary--text font-weight-bold text-center">Right</p>
              <v-img v-if="!!tripReport.Right" max-height="250" max-width="450" lazy-src="https://picsum.photos/id/11/10/6" :src="tripReport.Right"></v-img>
              <v-skeleton-loader type="image" boilerplate v-else></v-skeleton-loader>
            </v-col>
            <v-col cols="6">
              <p class="secondary--text font-weight-bold text-center">Dashboard</p>
              <v-img v-if="!!tripReport.Dashboard" max-height="250" max-width="450" lazy-src="https://picsum.photos/id/11/10/6" :src="tripReport.Dashboard"></v-img>
              <v-skeleton-loader type="image" boilerplate v-else></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {

  props: {
    toggle: {
      type: Function,
      required: true
    },
    reportType: {
      type: String,
      required: true
    },
    tripReport: {
      type: Object,
      required: true
    },
  },

  created() {
    console.log(this.tripReport)
  },

  data: () => ({
    order: {
      orderId: 'SN-CMPR-001'
    }
  })

}
</script>

<style>

</style>